import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import pendidikan from "./slices/pendidikanSlice";
import fakultas from "./slices/fakultasSlice";
import gedung from "./slices/gedungSlice";
import prodi from "./slices/prodiSlice";
import mahasiswa from "./slices/mahasiswaSlice";
import kelas from "./slices/kelasSlice";
import dosen from "./slices/dosenSlice";
import matkul from "./slices/matkulSlice";
import authdikti from "./slices/authdiktiSlice";
import periode from "./slices/periodeSlice";
import useraccess from "./slices/userAccessSlice";
import userroles from "./slices/userRolesSlice";
import adminKrs from "./slices/adminkrsSlice";
import kurikulum from "./slices/kurikulumSlice";
import kalenderAkademik from "./slices/kalenderAkademikSlice";
import semester from "./slices/semesterSlice";
import cbt from "./slices/cbtSlice";
import settingGambar from "./slices/admin/settingGambarSlice";
import registrasiUlang from "./slices/registrasiUlangSlice";
import commonFaq from './slices/commonFaqSlice';
import configPembayaran from './slices/configPembayaranSlice';
import detailDataDaftar from './slices/detailDataDaftarSlice';
import userManagement from "./slices/userManagementSlice";
import jalurPendaftaran from "./slices/jalurPendaftaranSlice";
import appData from "./slices/appDataSlice"
import payment from "./slices/payment/paymentSlice"
import paymentCicilan from "./slices/payment/paymentCicilanSlice"
import pay from "./slices/payment/paySlice"
import billingInfo from "./slices/payment/billingInfoSlice"
import pendaftaran from "./slices/pendaftaranSlice"
import ujian from "./slices/ujian/ujianSlice";
import validation from "./slices/validation/validationSlice";
import dashDaftar from "./slices/dashboard/dashDaftarSlice";
import dashPayment from './slices/dashboard/dashPaymentSlice';
import dataPendaftaran from './slices/pendaftaran/dataPendaftaranSlice';
import mataKuliah from "../views/admin-views/mata-kuliah/services/mataKuliahSlice";
import authenticationServices from "./slices/authentication/authenticationServices";
import downloadImage from "./slices/download/downloadImage";
import dashPilihan from "./slices/dashboard/dashPilihan";
import editPay from "./slices/payment/editPaySlice";
import reportDaftarUlang from "./slices/report/reportDaftarUlangSlice";
import configPendaftaran from "./slices/configPendaftaranSlice";

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        pendidikan,
        fakultas,
        gedung,
        prodi,
        mahasiswa,
        kelas,
        dosen,
        matkul,
        authdikti,
        periode,
        useraccess,
        userroles,
        adminKrs,
        kurikulum,
        kalenderAkademik,
        semester,
        cbt,
        settingGambar,
        registrasiUlang,
        commonFaq,
        configPembayaran,
        detailDataDaftar,
        userManagement,
        jalurPendaftaran,
        appData,
        payment,
        pendaftaran,
        paymentCicilan,
        billingInfo,
        pay,
        ujian,
        validation,
        dashDaftar,
        dashPayment,
        dataPendaftaran,
        mataKuliah,
        authenticationServices,
        downloadImage,
        dashPilihan,
        editPay,
        reportDaftarUlang,
        configPendaftaran,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer

import {APP_PREFIX_PATH} from "../AppConfig";
import React from "react";

export const userViewRoutes = [
    // KARTU REGISTRASI
    {
        key: 'kartu-registrasi',
        path: `${APP_PREFIX_PATH}/kartu-registrasi`,
        component: React.lazy(() => import('views/user-views/kartu-registrasi')),
    },
    {
        key: 'kartu-registrasi-show',
        path: `${APP_PREFIX_PATH}/kartu-registrasi-show`,
        component: React.lazy(() => import('views/user-views/kartu-registrasi/show')),
    },
    {
        key: 'kartu-registrasi-list',
        path: `${APP_PREFIX_PATH}/kartu-registrasi-list`,
        component: React.lazy(() => import('views/user-views/kartu-registrasi/list')),
    },
    {
        key: 'kartu-registrasi-edit',
        path: `${APP_PREFIX_PATH}/kartu-registrasi-list/edit`,
        component: React.lazy(() => import('views/user-views/kartu-registrasi/list/edit')),
    },
    {
        key: 'kartu-registrasi-detail',
        path: `${APP_PREFIX_PATH}/kartu-registrasi-list/view`,
        component: React.lazy(() => import('views/user-views/kartu-registrasi/list/view/ViewData')),
    },
    {
        key: 'kartu-registrasi-download',
        path: `${APP_PREFIX_PATH}/kartu-registrasi-list/download`,
        component: React.lazy(() => import('views/user-views/kartu-registrasi/list/download')),
    },
    // PENDAFTARAN
    {
        key: 'pendaftaran',
        path: `${APP_PREFIX_PATH}/pendaftaran`,
        component: React.lazy(() => import('views/user-views/pendaftaran/show')),
    },
    {
        key: 'registrasi.show',
        path: `${APP_PREFIX_PATH}/pendaftaran-show`,
        component: React.lazy(() => import('views/user-views/pendaftaran/step-pendaftaran')),
    },
    {
        key: 'registrasi.daftar',
        path: `${APP_PREFIX_PATH}/pendaftaran/daftar`,
        component: React.lazy(() => import('views/user-views/pendaftaran/show')),
    },

];
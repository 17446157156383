import {APP_PREFIX_PATH} from "../AppConfig";
import React from "react";

export const masterDataRoutes = [
    {
        key: 'pages.pendidikan',
        path: `${APP_PREFIX_PATH}/pages/pendidikan`,
        component: React.lazy(() => import('views/master-view/jenjang-pendidikan')),
    },
    {
        key: 'pages.pendidikan.add',
        path: `${APP_PREFIX_PATH}/pages/pendidikan/add`,
        component: React.lazy(() => import('views/master-view/jenjang-pendidikan/add')),
    },
    {
        key: 'pages.pendidikan.edit',
        path: `${APP_PREFIX_PATH}/pages/pendidikan/edit/:id`,
        component: React.lazy(() =>import('views/master-view/jenjang-pendidikan/edit')),
    },
    {
        key: 'pages.fakultas',
        path: `${APP_PREFIX_PATH}/pages/fakultas`,
        component: React.lazy(() => import('views/master-view/fakultas')),
    },
    {
        key: 'pages.fakultas.add',
        path: `${APP_PREFIX_PATH}/pages/fakultas/add`,
        component: React.lazy(() => import('views/master-view/fakultas/add')),
    },
    {
        key: 'pages.fakultas.edit',
        path: `${APP_PREFIX_PATH}/pages/fakultas/edit/:id`,
        component: React.lazy(() =>import('views/master-view/fakultas/edit')),
    },
    {
        key: 'pages.gedung',
        path: `${APP_PREFIX_PATH}/pages/gedung`,
        component: React.lazy(() => import('views/master-view/gedung')),
    },
    {
        key: 'pages.gedung.add',
        path: `${APP_PREFIX_PATH}/pages/gedung/add`,
        component: React.lazy(() => import('views/master-view/gedung/add')),
    },
    {
        key: 'pages.gedung.edit',
        path: `${APP_PREFIX_PATH}/pages/gedung/edit/:id`,
        component: React.lazy(() =>import('views/master-view/gedung/edit')),
    },
    {
        key: 'pages.prodi',
        path: `${APP_PREFIX_PATH}/pages/prodi`,
        component: React.lazy(() => import('views/master-view/program-studi')),
    },
    {
        key: 'pages.prodi.add',
        path: `${APP_PREFIX_PATH}/pages/prodi/add`,
        component: React.lazy(() => import('views/master-view/program-studi/add')),
    },
    {
        key: 'pages.prodi.edit',
        path: `${APP_PREFIX_PATH}/pages/prodi/edit/:id`,
        component: React.lazy(() =>import('views/master-view/program-studi/edit')),
    },
    {
        key: 'pages.mahasiswa',
        path: `${APP_PREFIX_PATH}/pages/mahasiswa`,
        component: React.lazy(() => import('views/master-view/mahasiswa')),
    },
    {
        key: 'pages.mahasiswa.add',
        path: `${APP_PREFIX_PATH}/pages/mahasiswa/add`,
        component: React.lazy(() => import('views/master-view/mahasiswa/add')),
    },
    {
        key: 'pages.mahasiswa.edit',
        path: `${APP_PREFIX_PATH}/pages/mahasiswa/edit/:id`,
        component: React.lazy(() =>import('views/master-view/mahasiswa/edit')),
    },
    {
        key: 'pages.kelas',
        path: `${APP_PREFIX_PATH}/pages/ruang-kelas`,
        component: React.lazy(() => import('views/master-view/kelas')),
    },
    {
        key: 'pages.kelas.add',
        path: `${APP_PREFIX_PATH}/pages/ruang-kelas/add`,
        component: React.lazy(() => import('views/master-view/kelas/add')),
    },
    {
        key: 'pages.kelas.edit',
        path: `${APP_PREFIX_PATH}/pages/ruang-kelas/edit/:id`,
        component: React.lazy(() =>import('views/master-view/kelas/edit')),
    },
    {
        key: 'pages.dosen',
        path: `${APP_PREFIX_PATH}/pages/dosen`,
        component: React.lazy(() => import('views/master-view/dosen')),
    },
    {
        key: 'pages.dosen.add',
        path: `${APP_PREFIX_PATH}/pages/dosen/add`,
        component: React.lazy(() => import('views/master-view/dosen/add')),
    },
    {
        key: 'pages.dosen.edit',
        path: `${APP_PREFIX_PATH}/pages/dosen/edit/:id`,
        component: React.lazy(() =>import('views/master-view/dosen/edit')),
    },
    {
        key: 'pages.dosen.detail',
        path: `${APP_PREFIX_PATH}/pages/dosen/view/:id`,
        component: React.lazy(() =>import('views/master-view/dosen/view')),
    },
    {
        key: 'pages.matkul',
        path: `${APP_PREFIX_PATH}/pages/matkul`,
        component: React.lazy(() => import('views/master-view/mata-kuliah/ShowMatkul')),
    },
    {
        key: 'pages.matkul.add',
        path: `${APP_PREFIX_PATH}/pages/matkul/add`,
        component: React.lazy(() => import('views/master-view/mata-kuliah/add')),
    },
    {
        key: 'pages.matkul.edit',
        path: `${APP_PREFIX_PATH}/pages/matkul/edit/:id`,
        component: React.lazy(() =>import('views/master-view/mata-kuliah/edit')),
    },
    {
        key: 'pages.authDikti',
        path: `${APP_PREFIX_PATH}/pages/auth-dikti`,
        component: React.lazy(() => import('views/master-view/auth-dikti')),
    },
    {
        key: 'pages.authDikti.add',
        path: `${APP_PREFIX_PATH}/pages/auth-dikti/add`,
        component: React.lazy(() => import('views/master-view/auth-dikti/add')),
    },
    {
        key: 'pages.authDikti.edit',
        path: `${APP_PREFIX_PATH}/pages/auth-dikti/edit/:id`,
        component: React.lazy(() =>import('views/master-view/auth-dikti/edit')),
    },
    {
        key: 'pages.periode',
        path: `${APP_PREFIX_PATH}/pages/periode`,
        component: React.lazy(() => import('views/master-view/periode')),
    },
    {
        key: 'pages.periode.add',
        path: `${APP_PREFIX_PATH}/pages/periode/add`,
        component: React.lazy(() => import('views/master-view/periode/add')),
    },
    {
        key: 'pages.periode.edit',
        path: `${APP_PREFIX_PATH}/pages/periode/edit/:id`,
        component: React.lazy(() =>import('views/master-view/periode/edit')),
    },
    {
        key: 'pages.user',
        path: `${APP_PREFIX_PATH}/master-data/user`,
        component: React.lazy(() => import('views/master-view/user-management/user-access')),
    },
    {
        key: 'pages.user.add',
        path: `${APP_PREFIX_PATH}/master-data/user/add`,
        component: React.lazy(() => import('views/master-view/user-management/user-access/add')),
    },
    {
        key: 'pages.user.edit',
        path: `${APP_PREFIX_PATH}/master-data/user/edit/:id`,
        component: React.lazy(() => import('views/master-view/user-management/user-access/edit')),
    },
    {
        key: 'pages.jadwal.mahasiswa.test',
        path: `${APP_PREFIX_PATH}/master-data/jadwal-mahasiswa`,
        component: React.lazy(() => import('views/master-view/jadwal-mahasiswa/')),
    },
    {
        key: 'pages.jadwal.mahasiswa',
        path: `${APP_PREFIX_PATH}/mahasiswa/jadwal`,
        component: React.lazy(() => import('views/master-view/jadwal-mahasiswa/')),
    },
    {
        key: 'pages.profile.mahasiswa',
        path: `${APP_PREFIX_PATH}/mahasiswa/profile`,
        component: React.lazy(() => import('views/master-view/profile-mahasiswa/')),
    },
    {
        key: 'pages.krs.mahasiswa',
        path: `${APP_PREFIX_PATH}/mahasiswa/krs`,
        component: React.lazy(() => import('views/master-view/mahasiswa-krs/ShowKrsMahasiswa')),
    },
    {
        key: 'pages.khs.mahasiswa',
        path: `${APP_PREFIX_PATH}/mahasiswa/khs`,
        component: React.lazy(() => import('views/master-view/mahasiswa-khs/ShowKhsMahasiswa')),
    },
    {
        key: 'pages.pembayaran.mahasiswa',
        path: `${APP_PREFIX_PATH}/mahasiswa/pembayaran`,
        component: React.lazy(() => import('views/master-view/mahasiswa-pembayaran/ShowPembayaranMahasiswa')),
    },
    {
        key: 'pages.kurikulum',
        path: `${APP_PREFIX_PATH}/pages/kurikulum`,
        component: React.lazy(() => import('views/master-view/kurikulum')),
    },
    {
        key: 'pages.kurikulum.add',
        path: `${APP_PREFIX_PATH}/pages/kurikulum/add`,
        component: React.lazy(() => import('views/master-view/kurikulum/add')),
    },
    {
        key: 'pages.kurikulum.edit',
        path: `${APP_PREFIX_PATH}/pages/kurikulum/edit/:id`,
        component: React.lazy(() =>import('views/master-view/kurikulum/edit')),
    },
    
    {
        key: 'pages.kalenderAkademik',
        path: `${APP_PREFIX_PATH}/pages/kalender-akademik`,
        component: React.lazy(() => import('views/master-view/kalender-akademik')),
    },
    {
        key: 'pages.kalenderAkademik.add',
        path: `${APP_PREFIX_PATH}/pages/kalender-akademik/add`,
        component: React.lazy(() => import('views/master-view/kalender-akademik/add')),
    },
    {
        key: 'pages.kalenderAkademik.edit',
        path: `${APP_PREFIX_PATH}/pages/kalender-akademik/edit/:id`,
        component: React.lazy(() =>import('views/master-view/kalender-akademik/edit')),
    },
    {
        key: 'pages.kalenderAkademik.detail',
        path: `${APP_PREFIX_PATH}/pages/kalender-akademik/view/:id`,
        component: React.lazy(() =>import('views/master-view/kalender-akademik/view')),
    },
    /*
    {
        key: 'pages.profile.mahasiswa',
        path: `${APP_PREFIX_PATH}/mahasiswa/profile/edit-profile`,
        component: React.lazy(() => import('views/master-detail/profile-mahasiswa/')),
    },
    {
        key: 'pages.profile.mahasiswa',
        path: `${APP_PREFIX_PATH}/mahasiswa/profile/change-password`,
        component: React.lazy(() => import('views/master-detail/profile-mahasiswa/')),
    },
    {
        key: 'pages.profile.mahasiswa',
        path: `${APP_PREFIX_PATH}/mahasiswa/profile/billing`,
        component: React.lazy(() => import('views/master-detail/profile-mahasiswa/')),
    },*/
    {
        key: 'pages.jadwal.dosen',
        path: `${APP_PREFIX_PATH}/dosen/jadwal`,
        component: React.lazy(() => import('views/master-view/jadwal-dosen/')),
    },
    {
        key: 'pages.jadwal.dosen',
        path: `${APP_PREFIX_PATH}/dosen/krs`,
        component: React.lazy(() => import('views/master-view/approval-krs')),
    },
    {
        key: 'pages.jadwal.dosen',
        path: `${APP_PREFIX_PATH}/dosen/input-nilai`,
        component: React.lazy(() => import('views/master-view/dosen-input-nilai/DosenInputNilai')),
    },
    //administrasi
    {
        key: 'administrasi.krs',
        path: `${APP_PREFIX_PATH}/master-data/administrasi/krs`,
        component: React.lazy(() => import('views/master-view/administration/krs')),
    },
    {
        key: 'administrasi.krs.add',
        path: `${APP_PREFIX_PATH}/master-data/administrasi/krs/add`,
        component: React.lazy(() => import('views/master-view/administration/krs/add')),
    },
    {
        key: 'administrasi.krs.edit',
        path: `${APP_PREFIX_PATH}/master-data/administrasi/krs/edit/:id`,
        component: React.lazy(() => import('views/master-view/administration/krs/edit')),
    },
    {
        key: 'master.cbt',
        path: `${APP_PREFIX_PATH}/cbt/master-cbt`,
        component: React.lazy(() => import('views/admin-views/master-cbt')),
    },
    {
        key: 'master.cbt.detail',
        path: `${APP_PREFIX_PATH}/cbt/master-cbt/detail/:id`,
        component: React.lazy(() => import('views/admin-views/master-cbt/detail')),
    },
    {
        key: 'cbt',
        path: `${APP_PREFIX_PATH}/master-data/cbt`,
        component: React.lazy(() => import('views/master-view/cbt')),
    },
    {
        key: 'cbt.test',
        path: `${APP_PREFIX_PATH}/master-data/cbt/test`,
        component: React.lazy(() => import('views/master-view/cbt/test')),
    },
    {
        key: 'cbt.hasil',
        path: `${APP_PREFIX_PATH}/master-data/cbt/test/hasil`,
        component: React.lazy(() => import('views/master-view/cbt/hasil')),
    },


    {
        key: 'ujian.hasil',
        path: `${APP_PREFIX_PATH}/ujian/hasil`,
        component: React.lazy(() => import('views/user-views/ujian')),
    },
    {
        key: 'ujian.validation',
        path: `${APP_PREFIX_PATH}/ujian/validation`,
        component: React.lazy(() => import('views/user-views/ujian/validation')),
    },
    {
        key: 'ujian.tes',
        path: `${APP_PREFIX_PATH}/ujian/tes`,
        component: React.lazy(() => import('views/user-views/ujian/tes')),
    },




    {
        key: 'pembayaran',
        path: `${APP_PREFIX_PATH}/pembayaran/show`,
        component: React.lazy(() => import('views/user-views/pembayaran')),
    },
    {
        key: 'pembayaran.invoice',
        path: `${APP_PREFIX_PATH}/pembayaran/show/invoice/:id`,
        component: React.lazy(() =>import('views/user-views/pembayaran/invoice')),
    },
    {
        key: 'pembayaran.invoice.detail',
        path: `${APP_PREFIX_PATH}/pembayaran/show/invoice/:id/print`,
        component: React.lazy(() =>import('views/user-views/pembayaran/invoice/View')),
    },

    {
        key: 'payment',
        path: `${APP_PREFIX_PATH}/payment`,
        component: React.lazy(() => import('views/user-views/payment')),
    },
/*    {
        key: 'payment.show',
        path: `${APP_PREFIX_PATH}/payment/show`,
        component: React.lazy(() => import('views/user-views/payment')),
    },*/
    {
        key: 'payment.detail',
        path: `${APP_PREFIX_PATH}/payment/show/detail/:id`,
        component: React.lazy(() => import('views/user-views/payment/detail')),
    },
    {
        key: 'payment.bayar',
        path: `${APP_PREFIX_PATH}/payment/show/bayar/:id`,
        component: React.lazy(() => import('views/user-views/payment/bayar')),
    },
    {
        key: 'payment.invoice',
        path: `${APP_PREFIX_PATH}/payment/show/invoice/:id`,
        component: React.lazy(() => import('views/user-views/payment/invoice')),
    },


    {
        key: 'faq',
        path: `${APP_PREFIX_PATH}/faq/show`,
        component: React.lazy(() => import('views/user-views/faq')),
    },
    {
        key: 'update-password',
        path: `${APP_PREFIX_PATH}/setting/update-password`,
        component: React.lazy(() => import('views/user-views/setting/update-password')),
    },


    {
        key: 'faq',
        path: `${APP_PREFIX_PATH}/fakultas/prodi`,
        component: React.lazy(() => import('views/admin-views/fakultas-prodi')),
    },
    {
        key: 'crud-cbt',
        path: `${APP_PREFIX_PATH}/bank-soal/view`,
        component: React.lazy(() => import('views/admin-views/crud-cbt')),
    },
    {
        key: 'crud-cbt.add',
        path: `${APP_PREFIX_PATH}/bank-soal/add`,
        component: React.lazy(() => import('views/admin-views/crud-cbt/component/add')),
    },
    {
        key: 'form-upload-taat-aturan',
        path: `${APP_PREFIX_PATH}/form-upload/aturan`,
        component: React.lazy(() => import('views/admin-views/form-upload/taat-aturan')),
    },
    {
        key: 'form-upload-rpl',
        path: `${APP_PREFIX_PATH}/form-upload/rpl`,
        component: React.lazy(() => import('views/admin-views/form-upload/rpl')),
    },
    {
        key: 'form-upload-assessment',
        path: `${APP_PREFIX_PATH}/form-upload/assessment`,
        component: React.lazy(() => import('views/admin-views/form-upload/assesment-pindahan')),
    },
    {
        key: 'setting-faq',
        path: `${APP_PREFIX_PATH}/setting-faq`,
        component: React.lazy(() => import('views/admin-views/setting/faq')),
    },
    {
        key: 'config-pembayaran',
        path: `${APP_PREFIX_PATH}/config-pembayaran/registrasi-ulang`,
        component: React.lazy(() => import('views/admin-views/setting/config-pembayaran/registrasi-ulang')),
    },
    {
        key: 'config-pembayaran-prodi',
        path: `${APP_PREFIX_PATH}/config-pembayaran/prodi`,
        component: React.lazy(() => import('views/admin-views/setting/config-pembayaran/prodi')),
    },
    {
        key: 'laporan-registrasi',
        path: `${APP_PREFIX_PATH}/laporan/pendaftaran`,
        component: React.lazy(() => import('views/admin-views/laporan/pendaftaran')),
    },
    {
        key: 'laporan-pembayaran',
        path: `${APP_PREFIX_PATH}/laporan/pembayaran`,
        component: React.lazy(() => import('views/admin-views/laporan/pembayaran')),
    },
    {
        key: 'laporan-pembayaran-detail',
        path: `${APP_PREFIX_PATH}/laporan/pembayaran_detail`,
        component: React.lazy(() => import('views/admin-views/laporan/pembayaran_detail')),
    },
    {
        key: 'laporan-daftar-ulang',
        path: `${APP_PREFIX_PATH}/laporan/daftar-ulang`,
        component: React.lazy(() => import('views/admin-views/laporan/daftar-ulang')),
    },
    {
        key: 'laporan-belum-daftar-ulang',
        path: `${APP_PREFIX_PATH}/laporan/belum-daftar-ulang`,
        component: React.lazy(() => import('views/admin-views/laporan/belum-daftar-ulang')),
    },
    {
        key: 'laporan-hasil-ujian',
        path: `${APP_PREFIX_PATH}/laporan/hasil-ujian`,
        component: React.lazy(() => import('views/admin-views/laporan/hasil-ujian')),
    },



    {
        key: 'setting-upload-carrousel',
        path: `${APP_PREFIX_PATH}/setting/upload/carrousel`,
        component: React.lazy(() => import('views/admin-views/config-upload/public-carrousel')),
    },
    {
        key: 'setting-akses-user',
        path: `${APP_PREFIX_PATH}/setting/akses-user`,
        component: React.lazy(() => import('views/admin-views/setting/user-management')),
    },
    {
        key: 'setting-upload-files',
        path: `${APP_PREFIX_PATH}/setting/upload-files`,
        component: React.lazy(() => import('views/admin-views/setting/upload-files/ListFilesUploadConfig')),
    },
    {
        key: 'setting-upload-files.add',
        path: `${APP_PREFIX_PATH}/setting/upload-files/add`,
        component: React.lazy(() => import('views/admin-views/setting/upload-files/UploadFilesConfig')),
    },
]
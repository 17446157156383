import jwtAuthAxios from "../../services/jwtAuth";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getConfigPendaftaranPageable = createAsyncThunk("config-pendaftaran/getAll", async({page = 1, size = 3}) => {
    try{
        let token = await localStorage.getItem('auth_token');
        jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        const response = await jwtAuthAxios.get(`v1/config-pendaftaran/pageable?page=${page}&size=${size}`)
        return response.data.data.result
    } catch(response){
        throw response
    }
});

export const getConfigPendaftaran = createAsyncThunk("config-pendaftaran/getAllNoPage", async() => {
    try{
        let token = await localStorage.getItem('auth_token');
        jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        const response = await jwtAuthAxios.get(`v1/config-pendaftaran`)
        return response.data
    } catch(response){
        throw response
    }
});

export const getByIdConfigPendaftaran = createAsyncThunk("config-pendaftaran/getByIdConfigPendaftaran", async(id) => {
    try{
        let token = await localStorage.getItem('auth_token');
        jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        const response = await jwtAuthAxios.get(`v1/config-pendaftaran-prodi/id-cfg/${id}`)
        return response.data
    } catch(response){
        throw response
    }
});

export const saveAllConfigPendaftaran = createAsyncThunk("config-pendaftaran/saveAll", async(payload) => {
    try{
        let token = await localStorage.getItem('auth_token');
        jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        const response = await jwtAuthAxios.post(`v1/config-pendaftaran/save-all`,payload)
        return response.data
    } catch(response){
        throw response
    }
});

export const updateAllConfigPendaftaran = createAsyncThunk("config-pendaftaran/updateAllConfigPendaftaran", async(payload) => {
    try{
        let token = await localStorage.getItem('auth_token');
        jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        const response = await jwtAuthAxios.put(`v1/config-pendaftaran/update-all`,payload)
        return response.data
    } catch(response){
        throw response
    }
});

export const deleteAllConfigPendaftaran = createAsyncThunk("config-pendaftaran/deleteAllConfigPendaftaran", async(id) => {
    try{
        let token = await localStorage.getItem('auth_token');
        jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        const response = await jwtAuthAxios.delete(`v1/config-pendaftaran/${id}`)
        return response.data
    } catch(response){
        throw response
    }
});

const initialState = {
    loading: false,
    dataConfigPendaftaran: [],
    dataProdiCfgPendaftaran: [],
}

const configPendaftaranSlice = createSlice({
    name:"configPendaftaran",
    initialState: initialState,
    extraReducers: (builder) => {
    builder
        .addCase(getConfigPendaftaran.pending, (state) => {
            state.loading = true;
        })
        .addCase(getConfigPendaftaran.fulfilled, (state, action) => {
            state.dataConfigPendaftaran = action.payload;
            state.loading = false;
        })
        .addCase(getConfigPendaftaran.rejected, (state, action) => {
            state.loading = false;
        })
    builder
        .addCase(getConfigPendaftaranPageable.pending, (state) => {
            state.loading = true;
        })
        .addCase(getConfigPendaftaranPageable.fulfilled, (state, action) => {
            state.dataConfigPendaftaran = action.payload;
            state.loading = false;
        })
        .addCase(getConfigPendaftaranPageable.rejected, (state, action) => {
            state.loading = false;
        })
    builder
        .addCase(saveAllConfigPendaftaran.pending, (state) => {
            state.loading = true;
        })
        .addCase(saveAllConfigPendaftaran.fulfilled, (state, action) => {
            state.dataConfigPendaftaran = action.payload;
            state.loading = false;
        })
        .addCase(saveAllConfigPendaftaran.rejected, (state, action) => {
            state.loading = false;
        })
    builder
        .addCase(getByIdConfigPendaftaran.pending, (state) => {
            state.loading = true;
        })
        .addCase(getByIdConfigPendaftaran.fulfilled, (state, action) => {
            state.dataProdiCfgPendaftaran = action.payload;
            state.loading = false;
        })
        .addCase(getByIdConfigPendaftaran.rejected, (state, action) => {
            state.loading = false;
        })
    builder
        .addCase(updateAllConfigPendaftaran.pending, (state) => {
            state.loading = true;
        })
        .addCase(updateAllConfigPendaftaran.fulfilled, (state, action) => {
            state.dataProdiCfgPendaftaran = action.payload;
            state.loading = false;
        })
        .addCase(updateAllConfigPendaftaran.rejected, (state, action) => {
            state.loading = false;
        })
    builder
        .addCase(deleteAllConfigPendaftaran.pending, (state) => {
            state.loading = true;
        })
        .addCase(deleteAllConfigPendaftaran.fulfilled, (state, action) => {
            state.dataProdiCfgPendaftaran = action.payload;
            state.loading = false;
        })
        .addCase(deleteAllConfigPendaftaran.rejected, (state, action) => {
            state.loading = false;
        })
        
    }
})
export const configPendaftaranSelector = (state) => state.configPendaftaran;
export default configPendaftaranSlice.reducer;